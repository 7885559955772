export default {
    state: {
        currentMenu: 'Main',
        recommendList: [],
        baseInfo: {},
        codeList: [],
        upiotObj: {},
    },
    mutations: {
        setCurrentMenu(state, item) {
            state.currentMenu = item
        },
        setRecommend(state, item) {
            state.recommendList = item
        },
        setBaseInfo(state, item) {
            state.baseInfo = item
        },
        setCodeInfo(state, item) {
            state.codeList = item
        },
        setUpiotObj(state, item) {
            state.upiotObj = item
        }
    }
}
