import axios from '../axios'
import qs from 'qs'

// 提交订单 -- 所属产品
export const submitOrder = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let orderObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'order/addOrder',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(orderObj)
    })
}

// 通过产品订单ID得到指定产品订单信息
export const findOrder = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let orderObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'order/findOrder',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(orderObj)
    })
}

// 修改订单服务的状态
export const updateOrderServerState = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let orderObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'order/updateOrderServerState',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(orderObj)
    })
}

// 获取全部通信服务消息数信息
export const getMsgPriceAll = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let orderObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'msgPrice/getMsgPriceAll',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(orderObj)
    })
}

// 看用户限制信息里面是否有该用户的限制信息
export const getAllUsersLimit = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let pubObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'usersLimit/getAllUsersLimit',
        method: 'post',
        pathType: 'lay',
        data: qs.stringify(pubObj)
    })
}

// 通过用户ID得到相应的限制信息
export const findUsersHisLimit = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let pubObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'usersHisLimit/findUsersHisLimit',
        method: 'post',
        pathType: 'lay',
        data: qs.stringify(pubObj)
    })
}


// 将产品套餐与平台用户绑定
export const buySetMealAfter = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let pubObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'subUsers/buySetMealAfter',
        method: 'post',
        pathType: 'lay',
        data: qs.stringify(pubObj)
    })
}

// 自动添加独享通信服务信息 -- 通信服务
export const autoAddCommInfo = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let pubObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'communicateServer/autoAddCommInfo',
        method: 'post',
        pathType: 'gateway',
        data: qs.stringify(pubObj)
    })
}

// 自动生成独享历史服务 -- 历史服务
export const autoDxHisServer = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let pubObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'historyServer/autoDxHisServer',
        method: 'post',
        pathType: 'gateway',
        data: qs.stringify(pubObj)
    })
}

// 自动添加独享应用服务 -- 应用服务
export const autoAddDxServer = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let pubObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'appServer/autoAddDxServer',
        method: 'post',
        pathType: 'gateway',
        data: qs.stringify(pubObj)
    })
}

// 同时添加三个服务
export const autoAddThreeServer = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let pubObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'communicateServer/autoAddThreeServer',
        method: 'post',
        pathType: 'gateway',
        data: qs.stringify(pubObj)
    })
}

// 自动添加物理系统生成的三个服务ID
export const autoAddPhysicalServer = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let pubObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'communicateServer/autoAddPhysicalServer',
        method: 'post',
        pathType: 'gateway',
        data: qs.stringify(pubObj)
    })
}

// 将用户购买的短信套餐添加进用户已有资源中
export const singleAddSmsNum = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let pubObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'usersOther/addUserIDAndSmsNum',
        method: 'post',
        pathType: 'lay',
        data: qs.stringify(pubObj)
    })
}

// 通过用户ID得到短信数量
export const byUserIDGetSmsNum = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let pubObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'usersOther/byUserIDGetSmsNum',
        method: 'post',
        pathType: 'lay',
        data: qs.stringify(pubObj)
    })
}

// 将用户购买的存储套餐添加进用户已有资源中
export const singleAddHisSaveNum = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let pubObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'usersHisLimit/singleAddHisSaveNum',
        method: 'post',
        pathType: 'lay',
        data: qs.stringify(pubObj)
    })
}

// 提交订单 -- 物联卡
export const addMpiOrder = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let orderObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'mpiOrder/addMpiOrder',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(orderObj)
    })
}

// 获取全部产品订单信息 -- 物联卡
export const getMpiOrderAll = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let orderObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'mpiOrder/getMpiOrderAll',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(orderObj)
    })
}

// 修改订单支付状态 -- 所属产品
export const editOrderPayState = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let orderObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'order/updateOrderPayState',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(orderObj)
    })
}

// 修改订单支付状态 -- 物联卡
export const updateMpiOrderPayState = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let orderObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'mpiOrder/updateMpiOrderPayState',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(orderObj)
    })
}

// 将产品信息与用户绑定
export const addProductInfoToData = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let orderObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'officalUsersProduct/addOfficalUsersProduct',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(orderObj)
    })
}

// 获取微信支付码
export const getWechatRP = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let orderObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'wxPay/getCodeUrl',
        method: 'post',
        pathType: 'pay',
        data: qs.stringify(orderObj)
    })
}

// 获取微信支付扫码结果
export const getWechatResult = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let orderObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'wxPay/queryTrade',
        method: 'post',
        pathType: 'pay',
        data: qs.stringify(orderObj)
    })
}

// 调用支付宝支付
export const getAliplay = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let orderObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'pay/aliPay',
        method: 'post',
        pathType: 'pay',
        data: qs.stringify(orderObj)
    })
}

// 支付宝订单是否支付成功
export const aliPayQuery = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let orderObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'pay/aliPayQuery',
        method: 'post',
        pathType: 'pay',
        data: qs.stringify(orderObj)
    })
}

// 查询所有支付方式
export const getAllPayMode = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let payObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'payMode/getPayModeAll',
        method: 'post',
        pathType: 'pay',
        data: qs.stringify(payObj)
    })
}

// 添加支付方式
export const addPayModeEvent = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let payObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'payMode/addPayMode',
        method: 'post',
        pathType: 'pay',
        data: qs.stringify(payObj)
    })
}

// 编辑修改支付方式
export const editPayModeEvent = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let editObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'payMode/editPayMode',
        method: 'post',
        pathType: 'pay',
        data: qs.stringify(editObj)
    })
}

// 删除支付方式
export const deletePayModeEvent = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let editObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'payMode/delPayMode',
        method: 'post',
        pathType: 'pay',
        data: qs.stringify(editObj)
    })
}

// 修改支付方式的使用
export const editIsUsePayModeEvent = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let isUseObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'payMode/editPayModeUse',
        method: 'post',
        pathType: 'pay',
        data: qs.stringify(isUseObj)
    })
}

// 获取全部产品套餐
export const getPubPrvProductAll = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let proObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'pubPrvProduct/getPubPrvProductAll',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(proObj)
    })
}

// 添加公有云私有云本地化套餐信息
export const addPubPrvProduct = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let proObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'pubPrvProduct/addPubPrvProduct',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(proObj)
    })
}

// 修改公有云私有云本地化套餐信息
export const editPubPrvProduct = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let proObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'pubPrvProduct/editPubPrvProduct',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(proObj)
    })
}

// 删除公有云私有云本地化套餐信息
export const delPubPrvProduct = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let proObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'pubPrvProduct/delPubPrvProduct',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(proObj)
    })
}

// 查询指定的公有云私有云本地化套餐信息
export const findPubPrvProduct = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let proObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'pubPrvProduct/findPubPrvProduct',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(proObj)
    })
}

// 升级套餐时计算价格
export const priceCalc = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let timeObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'pubPrvProduct/priceCalc',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(timeObj)
    })
}

// 降级套餐价格计算
export const downSetMealPriceCalc = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let timeObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'pubPrvProduct/downSetMealPriceCalc',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(timeObj)
    })
}

// 计算订单还剩余多少钱
export const calcSurplusPrice = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let timeObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'pubPrvProduct/calcSurplusPrice',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(timeObj)
    })
}

// 验证降级共享套餐用户下的限制信息是否符合降级条件
export const judgeCondition = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let timeObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'pubPrvProduct/judgeCondition',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(timeObj)
    })
}

// 获取购买时长数据
export const getPurchaseTimeData = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let timeObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'priceYearType/getPriceYearTypeAll',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(timeObj)
    })
}

// 通过价格年份ID得到指定价格年份信息
export const findPriceYearType = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let timeObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'priceYearType/findPriceYearType',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(timeObj)
    })
}

// 针对个人获取收货地址
export const getReceivingAddressAll = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let timeObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'receivingAddress/getReceivingAddressAll',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(timeObj)
    })
}

// 查询指定的收货地址信息
export const findReceivingAddress = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let timeObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'receivingAddress/findReceivingAddress',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(timeObj)
    })
}

// 添加收货地址信息
export const addReceivingAddress = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let timeObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'receivingAddress/addReceivingAddress',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(timeObj)
    })
}

// 通过地址ID删除收货地址
export const delRAToAddressID = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let timeObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'receivingAddress/delRAToAddressID',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(timeObj)
    })
}

// 修改收货地址信息
export const editReceivingAddress = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let timeObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'receivingAddress/editReceivingAddress',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(timeObj)
    })
}

// 修改默认收货地址信息
export const editDefaultAddress = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let timeObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'receivingAddress/editDefaultAddress',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(timeObj)
    })
}

// 得到全部网关型号数据信息
export const getGatewayModeAll = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let gateObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'gatewayMode/getGatewayModeAll',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(gateObj)
    })
}

// 获取指定网关系列产品
export const getAllGwProductTree = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let gateObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'product/getAllGwProductTree',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(gateObj)
    })
}

// 通过产品ID得到指定产品信息
export const findProduct = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let gateObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'product/findProduct',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(gateObj)
    })
}

// 通过ID得到网关型号信息
export const findGatewayMode = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let gateObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'gatewayMode/findGatewayMode',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(gateObj)
    })
}

// 添加购买时长折扣事件
export const addDiscountForPurchaseTime = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let disObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'priceYearType/addPriceYearType',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(disObj)
    })
}

// 编辑购买时长折扣事件
export const editDiscountForPurchaseTime = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let editObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'priceYearType/editPriceYearType',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(editObj)
    })
}

// 删除指定购买时长折扣事件
export const deleteDiscountForPurchaseTime = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let delObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'priceYearType/delPriceYearType',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(delObj)
    })
}
// 获取全部通信服务消息数信息
export const getMsgPriceAllEvent = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let proObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'msgPrice/getMsgPriceAll',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(proObj)
    })
}

// 通过消息数ID得到指定消息数信息
export const findMsgPrice = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let proObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'msgPrice/findMsgPrice',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(proObj)
    })
}

// 添加产品
export const addProductEvent = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let addObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'product/addProduct',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(addObj)
    })
}

// 编辑产品
export const editProductEvent = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let editObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'product/editProduct',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(editObj)
    })
}

// 删除产品
export const deleteProductEvent = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let delObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'product/delProduct',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(delObj)
    })
}

// 得到官网用户下有多少个历史服务并返回
export const getOfficalUsersIDBelongHisServer = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let delObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'usersHisLimit/getOfficalUsersIDBelongHisServer',
        method: 'post',
        pathType: 'lay',
        data: qs.stringify(delObj)
    })
}
