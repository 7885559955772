import { api as viewerApi } from "v-viewer"

export default {
    // 获取当前日期时间
    getNowDateTime() {
        const myDate = new Date;
        const year = myDate.getFullYear(); //获取当前年
        let mon = myDate.getMonth() + 1; //获取当前月
        let date = myDate.getDate(); //获取当前日
        mon = (mon < 10) ? '0' + mon : mon;
        date = (date < 10) ? '0' + date : date;
        let h = myDate.getHours();//获取当前小时数(0-23)
        let m = myDate.getMinutes();//获取当前分钟数(0-59)
        let s = myDate.getSeconds();//获取当前秒
        m = (m < 10) ? '0' + m : m;
        s = (s < 10) ? '0' + s : s;
        return (year + "-" + mon + "-" + date + ' ' + h + ":" + m + ":" + s);
    },
    // 获取当前日期
    getNowDate() {
        const nowDate = new Date()
        const date = {
            year: nowDate.getFullYear(),
            month: nowDate.getMonth() + 1,
            day: nowDate.getDate()
        }
        const newmonth = date.month > 9 ? date.month : '0' + date.month
        const newDay = date.day > 9 ? date.day : '0' + date.day
        return '' + date.year + '-' + newmonth + '-' + newDay
    },
    // 获取当前月
    getNowMouth() {
        const nowDate = new Date()
        const date = {
            year: nowDate.getFullYear(),
            month: nowDate.getMonth() + 1,
            day: nowDate.getDate()
        }
        const newmonth = date.month > 9 ? date.month : '0' + date.month
        const newDay = date.day > 9 ? date.day : '0' + date.day
        return '' + date.year + '-' + newmonth
    },
    // 获取当前时间
    getNowTime() {
        const nowDate = new Date()
        const time = {
            hour: nowDate.getHours(),
            minute: nowDate.getMinutes(),
            second: nowDate.getSeconds()
        }
        const newHour = (time.hour > 9) ? time.hour : '0' + time.hour
        const newMinute = (time.minute > 9) ? time.minute : '0' + time.minute
        const newSecond = (time.second > 9) ? time.second : '0' + time.second
        return '' + newHour + ':' + newMinute + ':' + newSecond
    },
    // 获取基础日期+天数(向后)
    getNewDay(dateTemp, days) {
        const nDate = new Date(dateTemp)//转换为MM-DD-YYYY
        const millSeconds = Math.abs(nDate) + (days * 24 * 60 * 60 * 1000)
        const rDate = new Date(millSeconds)
        const year = rDate.getFullYear()
        let month = rDate.getMonth() + 1
        if (month < 10) month = "0" + month
        let date = rDate.getDate()
        if (date < 10) date = "0" + date
        return (year + "-" + month + "-" + date)
    },
    // 获取上一个月日期(向前)
    getPreMonth(date) {
        var arr = date.split('-')
        var year = arr[0] //获取当前日期的年份
        var month = arr[1]//获取当前日期的月份
        var day = arr[2] //获取当前日期的日
        var days = new Date(year, month, 0)
        days = days.getDate() //获取当前日期中月的天数
        var year2 = year
        var month2 = parseInt(month) - 1
        if (month2 == 0) {
            year2 = parseInt(year2) - 1
            month2 = 12
        }
        var day2 = day
        var days2 = new Date(year2, month2, 0)
        days2 = days2.getDate()
        if (day2 > days2) {
            day2 = days2
        }
        if (month2 < 10) {
            month2 = '0' + month2
        }
        var t2 = year2 + '-' + month2 + '-' + day2
        return t2
    },
    // 时间戳转换为日期格式
    formatDateTime(value) {
        if (value == null) {
            return ''
        } else {
            const date = new Date(value)
            const y = date.getFullYear() // 年
            let MM = date.getMonth() + 1 // 月
            MM = MM < 10 ? ('0' + MM) : MM
            let d = date.getDate() // 日
            d = d < 10 ? ('0' + d) : d
            let h = date.getHours() // 时
            h = h < 10 ? ('0' + h) : h
            let m = date.getMinutes()// 分
            m = m < 10 ? ('0' + m) : m
            let s = date.getSeconds()// 秒
            s = s < 10 ? ('0' + s) : s
            return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s
        }
    },
    // 将时间戳转换为时间
    getLocalTime(nS) {
        //将时间戳（十三位时间）转换成时间格式
        let date = new Date(nS);
        let mm = date.getMinutes();
        let s = date.getSeconds();
        mm = mm < 10 ? "0" + mm : mm;
        s = s < 10 ? "0" + s : s;
        return '00' + ':' + mm + ':' + s;
    },
    // 将日期时间转换为时间戳并进行计算
    dateTurnTimestamp(time) {
        var date = new Date(time);
        var endTime = date.getTime();
        var nowTimestamp = new Date().getTime();
        var lastTime = endTime - nowTimestamp;
        return Math.round(lastTime / 86400000)
    },
    // 获取最近六个月
    getLastSixMon() {
        var data = new Date();
        //获取年
        var year = data.getFullYear();
        //获取月
        var mon = data.getMonth() + 1;
        var arry = new Array();
        for (var i = 0; i < 6; i++) {
            if (mon <= 0) {
                year = year - 1;
                mon = mon + 12;
            }
            if (mon < 10) {
                mon = "0" + mon;
            }
            arry[i] = year + mon;
            mon = mon - 1;
        }
        return arry;
    },
    //整理数据位树形
    getTree(menuList, parentID) {
        var tree = []
        var temp
        for (var i = 0; i < menuList.length; i++) {
            if (menuList[i].parent == parentID) {
                var obj = {}
                obj = menuList[i]
                temp = this.getTree(menuList, menuList[i].key)
                if (temp.length > 0) {
                    obj.children = temp
                }
                tree.push(obj)
            }
        }
        return tree;
    },
    // 转换为base64
    getBase64(file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader()
            let fileResult = ''
            reader.readAsDataURL(file) //开始转
            reader.onload = function () {
                fileResult = reader.result
            } //转 失败
            reader.onerror = function (error) {
                reject(error)
            } //转 结束  咱就 resolve 出去
            reader.onloadend = function () {
                resolve(fileResult)
            }
        })
    },
    // 将base64图片转为file文件类型
    base64ToFile(urlData, fileName) {
        let arr = urlData.split(',');
        let mime = arr[0].match(/:(.*?);/)[1];
        let bytes = atob(arr[1]); // 解码base64
        let n = bytes.length
        let ia = new Uint8Array(n);
        while (n--) {
            ia[n] = bytes.charCodeAt(n);
        }
        return new File([ia], fileName, {type: mime});
    },
    // 将数字转为金额格式
    formatMoney(num) {
        num = Number(num).toFixed(2) + '';
        return num.replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
    },
    // 生成随机数
    generateRdStr(len) {
        let number = '', possible = "0123456789"
        for (var i = 0; i < len; i++) {
            number += possible.charAt(Math.floor(Math.random() * possible.length))
        }
        return number
    },
    // 验证是否为空
    judgeIsEmpty(string) {
        let flag = false
        if (string == "" || string == "null" || string == "undefined" || string == null) {
            flag = true
        }
        return flag
    },
    // 验证手机格式
    judgePhone(phone) {
        let reg = /^1[3456789]\d{9}$/
        return reg.test(phone)
    },
    //验证是否是邮箱
    isEmail(valEmail) {
        var regEmail = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
        return regEmail.test(valEmail);
    },
    // 获取地址栏传递参数
    getUrlKey(name) {
        return (
            decodeURIComponent(
                (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
                    location.href
                ) || [, ''])[1].replace(/\+/g, '%20')
            ) || null
        )
    },
    // 一个数组按指定长度分割
    chunk(array, size) {
        //获取数组的长度，如果你传入的不是数组，那么获取到的就是undefined
        const length = array.length
        //判断不是数组，或者size没有设置，size小于1，就返回空数组
        if (!length || !size || size < 1) {
            return []
        }
        //核心部分
        let index = 0 //用来表示切割元素的范围start
        let resIndex = 0 //用来递增表示输出数组的下标
        //根据length和size算出输出数组的长度，并且创建它。
        let result = new Array(Math.ceil(length / size))
        //进行循环
        while (index < length) {
            //循环过程中设置result[0]和result[1]的值。该值根据array.slice切割得到。
            result[resIndex++] = array.slice(index, (index += size))
        }
        //输出新数组
        return result
    },
    // 验证是否为邮政编号
    isRightPostCode(code) {
        let reg = /^[0-9]{6}$/;
        return reg.test(code);
    },
    // 通过原型方法直接准确的获得当前时间的毫秒值 getTime()
    getTimestamp(date) {
        return (new Date(date.replace(/-/g, '/'))).getTime()
    },
    // 资源盘大小转换
    byByteGetSize(limit) {
        var size = "";
        if (limit < 1 * 1024) {                            //小于1KB，则转化成B
            size = limit.toFixed(2) + "B"
        } else if (limit < 1 * 1024 * 1024) {            //小于1MB，则转化成KB
            size = (limit / 1024).toFixed(2) + "KB"
        } else if (limit < 1 * 1024 * 1024 * 1024) {        //小于1GB，则转化成MB
            size = (limit / (1024 * 1024)).toFixed(2) + "MB"
        } else {                                            //其他转化成GB
            size = (limit / (1024 * 1024 * 1024)).toFixed(2) + "GB"
        }

        var sizeStr = size + "";                        //转成字符串
        var index = sizeStr.indexOf(".");                    //获取小数点处的索引
        var dou = sizeStr.substr(index + 1, 2)            //获取小数点后两位的值
        if (dou == "00") {                                //判断后两位是否为00，如果是则删除00
            return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2)
        }
        return size;
    },
    // 整理最近六个月流量数据
    setMonthUse(obj) {
        var min;
        for (var i = 0; i < obj.length; i++) {
            for (var j = i; j < obj.length; j++) {
                if (obj[i].name > obj[j].name) {
                    min = obj[j];
                    obj[j] = obj[i];
                    obj[i] = min;
                }
            }
        }
        return obj;
    },
    // 图片查看
    showViewImgDialog(imgSrc) {
        let images = [{'data-source': ""}]
        if (["", null, undefined].includes(imgSrc)) {
            this.$common.notify("error", "请先选择要查看的图片")  // 这里改成自己的消息弹框提示组件
            return false
        }
        images[0]['data-source'] = imgSrc
        viewerApi({
            options: {
                toolbar: true,
                url: 'data-source',
                initialViewIndex: 0
            },
            images: images
        })
    },
}
