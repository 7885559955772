import md5 from 'js-md5'
import axios from '../axios'
import qs from 'qs'

// 通过用户ID得到物联网卡卡号
export const byUserIDGetGatewayIDEvent = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let logObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'subUsers/byUsersIDGetPhCard',
        method: 'post',
        pathType: 'lay',
        data: qs.stringify(logObj)
    })
}

// 获取物联网卡全部价格信息
export const getRechargePriceAll = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let gatewayObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'rechargePrice/getRechargePriceAll',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(gatewayObj)
    })
}

// 添加物联网卡价格信息
export const addRechargePrice = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let gatewayObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'rechargePrice/addRechargePrice',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(gatewayObj)
    })
}

// 修改物联网价格信息
export const editRechargePrice = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let gatewayObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'rechargePrice/editRechargePrice',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(gatewayObj)
    })
}

// 删除物联网价格信息
export const delRechargePrice = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let gatewayObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'rechargePrice/delRechargePrice',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(gatewayObj)
    })
}

// 通过充值ID查询物联网卡价格信息
export const findRechargePrice = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let gatewayObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'rechargePrice/findRechargePrice',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(gatewayObj)
    })
}

// 中转
export const pmTransfer = (data) => {
    return axios.request({
        url: 'pmTransfer/resultBase',
        method: 'post',
        pathType: 'transfer',
        data: qs.stringify(data)
    })
}

// 批量查询卡信息
export const getCardInfoForBatch = (data) => {
    let tempSign = JSON.stringify(data) + api_secret
    let tempUrl = api_key + '/batch/card/info/?_sign=' + md5(tempSign)
    return axios.request({
        url: tempUrl,
        method: 'post',
        pathType: 'upiot',
        data: data
    })
}

// 批量查询单日用量信息
export const getDailyUsagelog = (data) => {
    let tempSign = JSON.stringify(data) + api_secret
    let tempUrl = api_key + '/card/daily/usagelog/?_sign=' + md5(tempSign)
    return axios.request({
        url: tempUrl,
        method: 'post',
        pathType: 'upiot',
        data: data
    })
}

// 物联网卡每日使用量
export const getCardUsagelog = (card, date) => {
    let tempSign = api_secret
    let tempUrl = api_key + '/card/' + card + '/usagelog/' + date + '/delta/?_sign=' + md5(tempSign)
    return axios.request({url: tempUrl, method: 'get', pathType: 'upiot'})
}

// 物联网卡月用量查询
export const getCardUsagelogMonth = (data) => {
    let tempSign = JSON.stringify(data) + api_secret
    let tempUrl = api_key + '/card_usage_info/?_sign=' + md5(tempSign)
    return axios.request({
        url: tempUrl,
        method: 'post',
        pathType: 'upiot',
        data: data
    })
}

// 物联卡充值产品查询
export const getCardcharge = (datcard) => {
    let tempSign = api_secret
    let tempUrl = api_key + '/card/' + datcard + '/charge/products/?_sign=' + md5(tempSign)
    return axios.request({url: tempUrl, method: 'get', pathType: 'upiot'})
}

// 物联卡本月到期卡详情查询
export const batchCardExpiryDate = () => {
    let tempSign = api_secret
    let tempUrl = api_key + '/batch/card/expiry_date/?_sign=' + md5(tempSign)
    return axios.request({url: tempUrl, method: 'get', pathType: 'upiot'})
}

// 物联卡充值
export const cardChargeEvent = (iccid, data) => {
    let tempSign = JSON.stringify(data) + api_secret
    let tempUrl = api_key + '/card/' + iccid + '/charge/?_sign=' + md5(tempSign)
    return axios.request({
        url: tempUrl,
        method: 'post',
        pathType: 'upiot',
        data: data
    })
}

// 获取账号余额
export const getAccountBalance = () => {
    let tempUrl = api_key + '/balance/?_sign=' + md5(api_secret)
    return axios.request({url: tempUrl, method: 'get', pathType: 'upiot'})
}

// 接口中转
export const resultJsonInfo = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let delObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'pmTransfer/resultJsonInfo',
        method: 'post',
        pathType: 'transfer',
        data: qs.stringify(delObj)
    })
}

// 接口中转 -- get
export const resultJsonInfoToGet = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let delObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'pmTransfer/resultJsonInfoToGet',
        method: 'post',
        pathType: 'transfer',
        data: qs.stringify(delObj)
    })
}
