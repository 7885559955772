export default {
    /**
     * @param isCollapse 菜单栏收放标志
     * @param permissionAll 所有菜单
     * @param leftPermissionMenu 左侧菜单
     * @param perms 页面按钮权限
     * @param productNo 产品唯一ID
     * @param createOrder 形成订单
     * @param terminalIP 终端IP
     * @param topMenu  顶部菜单
     */
    state: {
        isCollapse: false,
        currentMenu: null,
        permissionAll: [],
        leftPermissionMenu: [],
        perms: [],
        productNo: null,
        orderCode: null,
        terminalIP: null,
        orderDate: {},
        topMenu: []
    },
    mutations: {
        collapseMenu(state) {
            state.isCollapse = !state.isCollapse
        },
        setInitLeftMenu(state, val) {
            state.leftPermissionMenu = val
        },
        setInitAllPermission(state, val) {
            state.permissionAll = val
        },
        selectMenu(state, val) {
            state.currentMenu = val
        },
        setPerms(state, val) {
            state.perms = val
        },
        setThemeColor(state, val) {
            state.themeColor = val
        },
        setProductNo(state, val) {
            state.productNo = val
        },
        setOrderInfo(state, val) {
            state.createOrder = val
        },
        setOrderCode(state, val) {
            state.orderCode = val
        },
        setTerminalIP(state, val) {
            state.terminalIP = val
        },
        setOrderDate(state, val) {
            state.orderDate = val
        },
        clearMenu(state) {
            state.leftPermissionMenu = []
        },
        setTopMenu(state, val) {
            state.topMenu = val
        },
    }
}
