import axios from '../axios'
import qs from 'qs'

const usersID = '21B3587B-12T2-5879-889A-24D6E3D8D9DA'
const authToken = 'cm1uN25iRXV3YXJaZmc3RGRLNmgrZz09'
let dataObj = {
    usersID: usersID,
    authToken: authToken
}

/**
 * @param 真实数据请求
 */
// 页面请求
export const getHomeResource = (allTypeInfoAll) => {
    let newObj = dataObj
    newObj.homeTypeIDStr = JSON.stringify(allTypeInfoAll)
    return axios.request({
        url: 'homeInfo/officalByTypeIDGetHomeInfo',
        method: 'post',
        pathType: 'home',
        data: qs.stringify(newObj)
    })
}

// 注册登录验证码请求
export const getCode = (data) => {
    let newObj = dataObj
    let codeObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'sendKey/sendPhoneKey',
        method: 'post',
        pathType: 'msg',
        data: qs.stringify(codeObj)
    })
}
// 获取邮箱验证码
export const getEmailCode = (data) => {
    let newObj = dataObj
    let codeObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'sendKey/sendEmailKey',
        method: 'post',
        pathType: 'msg',
        data: qs.stringify(codeObj)
    })
}
// 获取AppID
export const getAPPID = () => {
    let newObj = dataObj
    newObj.parType = 'scanLogin'
    return axios.request({
        url: 'sysPar/findSysParameter',
        method: 'post',
        pathType: 'lay',
        data: qs.stringify(newObj)
    })
}
// 验证验证码是否输入正确
export const judgeCode = (data) => {
    let newObj = dataObj
    let judge = Object.assign({}, data, newObj)
    return axios.request({
        url: 'sendKey/judgeKeyCode',
        method: 'post',
        pathType: 'msg',
        data: qs.stringify(judge)
    })
}
// 信息注册事件
export const registerEvent = (data) => {
    let newObj = dataObj
    let anotherObj = Object.assign({}, data, newObj)
    anotherObj.isUse = 1
    return axios.request({
        url: 'officalUsers/officalRegister',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(anotherObj)
    })
}
// 省市信息
export const getCapitalInfo = () => {
    let newObj = dataObj
    return axios.request({
        url: 'province/getProvinceAll',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(newObj)
    })
}
// 城市信息
export const getCityInfo = (father) => {
    let newObj = dataObj
    newObj.father = father
    return axios.request({
        url: 'city/getCityAll',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(newObj)
    })
}
// 区县信息
export const getAreaAll = (father) => {
    let newObj = dataObj
    newObj.father = father
    return axios.request({
        url: 'area/getAreaAll',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(newObj)
    })
}

// 提交企业注册审核信息
export const handCompanyInfo = (data) => {
    let newObj = dataObj
    let comObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'userCompany/addUserCompany',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(comObj)
    })
}
// 触发消息推送
export const touchSendMessage = (data) => {
    return axios.request({
        url: 'platFormUsers/registerSuccessSendMsg',
        method: 'post',
        pathType: 'home',
        data: qs.stringify(data)
    })
}
// 获取微信接口参数
export const getWechatParamer = (data) => {
    let newObj = dataObj
    let pamObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'sysPar/findSysParameter',
        method: 'post',
        pathType: 'lay',
        data: qs.stringify(pamObj)
    })
}

// 中转调用微信接口获取数据
export const getWechatInfoByWechat = (data) => {
    let newObj = dataObj
    let weObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'wxInterface/useWxApi',
        method: 'post',
        pathType: 'lay',
        data: qs.stringify(weObj)
    })
}
