<template>
    <div id="app">
        <!-- 路由占位符 -->
        <router-view v-if="isRouterAlive"></router-view>
    </div>
</template>

<script>
export default {
    name: 'app',
    created() {
        // document.addEventListener("visibilitychange", function () {
        //   if (store.getters.token != getToken()) {
        //     location.reload();
        //   }
        // });
        //在页面加载时读取sessionStorage里的状态信息
        if (sessionStorage.getItem('storeState')) {
            //replaceState，替换store的根状态
            this.$store.replaceState(
                Object.assign(
                    {},
                    this.$store.state,
                    JSON.parse(sessionStorage.getItem('storeState'))
                )
            )
        }
        //在页面刷新时将vuex里的信息保存到sessionStorage里
        window.addEventListener('beforeunload', () => {
            this.$store.commit('setCurrentMenu', '')
            sessionStorage.setItem('storeState', JSON.stringify(this.$store.state))
        })
        //ios废弃了beforeunload，使用pagehide代替
        window.addEventListener('pagehide', () => {
            sessionStorage.setItem('storeState', JSON.stringify(this.$store.state))
        })
    },
    provide() {
        return {
            reload: this.reload
        }
    },
    data() {
        return {
            isRouterAlive: true
        }
    },
    methods: {
        reload() {
            this.isRouterAlive = false
            this.$nextTick(() => {
                this.isRouterAlive = true
            })
        },
        test() {
            console.log('refresh')
        }
    }
}
</script>

<style></style>
