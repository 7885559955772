import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './assets/css/public.css'
import './assets/css/main.css'
import './assets/css/phone.css'
import '../node_modules/font-awesome/css/font-awesome.min.css'
import VueParticles from 'vue-particles'
import animated from 'animate.css'
import apiUrl from './components/global.vue'
import './utils/rem'
import AFTableColumn from 'af-table-column'
import * as echarts from 'echarts'
import 'echarts-liquidfill'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

import common from './common/utils.js'
import axios from 'axios'
import store from './store/index.js'
import pubV from './common/publicV.js'

// 引入BootStrap
// import 'bootstrap/dist/css/bootstrap.min.css'

Vue.prototype.$http = axios
Vue.config.productionTip = false
Vue.prototype.apiUrl = apiUrl
Vue.prototype.$common = common
Vue.prototype.$public = pubV
Vue.prototype.$echarts = echarts

// // 定义一个开关
// const mock = true
// if(mock){
//   require('./mock/mock')
// }

Vue.use(VueParticles)
Vue.use(animated)
Vue.use(AFTableColumn)
Vue.use(Viewer)

Viewer.setDefaults({
    Options: {
        'inline': false,
        'button': false, //右上角按钮
        "navbar": false, //底部缩略图
        "title": false, //当前图片标题
        "toolbar": true, //底部工具栏
        "tooltip": true, //显示缩放百分比
        "movable": true, //是否可以移动
        "zoomable": true, //是否可以缩放
        "rotatable": true, //是否可旋转
        "scalable": true, //是否可翻转
        "transition": false, //使用 CSS3 过度
        "fullscreen": false, //播放时是否全屏
        "keyboard": true, //是否支持键盘
        "url": "data-source"
    }
})

// // 滚动到顶部20221020 不生效
// router.beforeEach((to, from, next) => {
//   // 路由跳转后，让页面回到顶部
//   // chrome
//   document.body.scrollTop = 0; // firefox
//   document.documentElement.scrollTop = 0; // safari
//   window.pageYOffset = 0; // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
//   next();
//   //to 即将进入的路由
//   //from 即将离开的路由
//   //next 放行
// });

// // 处理页面跳转后不在顶部问题 20221021 不生效
// router.afterEach((to, from, next) => {
//  // window.scrollTo(0, 0)
// window.scroll(0, 0);
// })


const rootVue = new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
export default rootVue






