export default {
    /*
     *@param productTreeNo 产品唯一标识
     *@param productType 产品类型 1：产品套餐 2：短信套餐、存储套餐 4:边缘网关
     *@param mealType 区分短信套餐、数据存储套餐标识
     *@param msgPriceType 区分短信套餐、数据存储套餐中套餐类型
     *@param oldOrderCode 进行续费、升级时所需的旧订单编号
     *@param isRenew 是否续费标识
     *@param unpaid  是否待付款标识
     *@param isUpiot 是否物联卡充值
     *@param upiotObj 物联卡充值对象信息
     *@param jumpOrderType 查看订单跳转标识
     *@param isUpReDe 0：正常支付；1: 升级；2：续费；3：降级, 4:待支付
    */
    state: {
        productTreeNo: null,
        productType: null,
        mealType: null,
        msgPriceType: null,
        oldOrderCode: null,
        isUpiot: false,
        upiotObj: {},
        jumpOrderType: 1,
        isUpReDe: 0,
        editOrderObj: null,
        alipayCode: null
    },
    mutations: {
        setProductTreeNo(state, val) {
            state.productTreeNo = val
        },
        setProductType(state, val) {
            state.productType = val
        },
        setMealType(state, val) {
            state.mealType = val
        },
        setMsgPriceType(state, val) {
            state.msgPriceType = val
        },
        setOldOrderCode(state, val) {
            state.oldOrderCode = val
        },
        setIsUpiot(state, val) {
            state.isUpiot = val
        },
        setUpiotObj(state, val) {
            state.upiotObj = val
        },
        setJumpOrderType(state, val) {
            state.jumpOrderType = val
        },
        setIsUpReDe(state, val) {
            state.isUpReDe = val
        },
        setEditOrderObj(state, val) {
            state.editOrderObj = val
        },
        setAlipayCode(state, val) {
            state.alipayCode = val
        }
    }
}
