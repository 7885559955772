import axios from '../axios'
import qs from 'qs'

// 登录
export const loginEvent = (data) => {
    return axios.request({
        url: 'officalLogin/officalUsersLogin',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(data)
    })
}

// 通过账号ID获取Token
export const byOfficeUsersIDGetToken = (data) => {
    return axios.request({
        url: 'officalLogin/loginSetSaToken',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(data)
    })
}

// 通过用户登录runID获取指定角色菜单
export const getPermissionByRoleID = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let menuObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'officalPermission/byRoleIDGetPermission',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(menuObj)
    })
}

// 请求全部菜单
export const getPermissionAll = () => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    return axios.request({
        url: 'officalPermission/getOfficalPermissionAll',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(newObj)
    })
}

// 删除指定菜单
export const deleteAssignMenu = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let delObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'officalPermission/delPermission',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(delObj)
    })
}

// 新增菜单项
export const addMenuEvent = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let addObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'officalPermission/addOfficalPermission',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(addObj)
    })
}

//编辑菜单
export const editMenuEvent = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let editObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'officalPermission/editPermission',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(editObj)
    })
}

// 获取角色数据表
export const getRoleList = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let allObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'usersGroup/getUsersGroupAll',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(allObj)
    })
}

// 添加角色
export const addCharacterEvent = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let addRObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'usersGroup/addUsersGroup',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(addRObj)
    })
}

// 删除角色
export const deleteCharacterEvent = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let delRObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'usersGroup/delUsersGroup',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(delRObj)
    })
}

// 编辑角色
export const editCharacterEvent = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let delRObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'usersGroup/editUsersGroup',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(delRObj)
    })
}

// 获取指定角色权限标识
export const getPermissions = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let getPObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'officalUsersGroupPermission/getUsersGroupPermissionAll',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(getPObj)
    })
}

// 为指定角色添加权限标识
export const addPermissions = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let addPObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'officalUsersGroupPermission/addOfficalUsersGroupPermission',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(addPObj)
    })
}

// 用户升级降级后修改相关限制信息操作
export const editLimtInfo = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let addPObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'subUsers/editLimtInfo',
        method: 'post',
        pathType: 'lay',
        data: qs.stringify(addPObj)
    })
}

// 获取所有用户信息
export const getAllUsersInfo = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let allObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'officalUsers/getOfficalUsersAll',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(allObj)
    })
}

// 修改官网用户信息
export const editOfficeUsersInfo = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let userObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'officalUsers/editOfficalUsers',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(userObj)
    })
}

// 修改用户信息是否为管理员
export const editAdminStatusForPointOfficalUser = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let editObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'officalUsers/editOfficalIsAdmin',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(editObj)
    })
}

// 删除官网用户信息
export const deleteOfficeInfo = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let userObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'officalUsers/delOfficalUsers',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(userObj)
    })
}

// 获取全部订单 -- 所属产品
export const getOrderInfoForAccount = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let roderObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'order/getOrderAll',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(roderObj)
    })
}

// 修改指定订单信息
export const editOrderForPoint = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let roderObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'order/editOrder',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(roderObj)
    })
}

// 获取全部充值记录 -- 物联卡
export const getMpiOrderAll = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let roderObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'mpiOrder/getMpiOrderAll',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(roderObj)
    })
}

// 添加物联卡订单
export const addMpiOrder = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let addLotObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'mpiOrder/addMpiOrder',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(addLotObj)
    })
}

// 编辑物联卡订单
export const editMpiOrder = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let addLotObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'mpiOrder/editMpiOrder',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(addLotObj)
    })
}

// 删除指定订单信息
export const deleteOrder = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let delObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'order/delOrder',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(delObj)
    })
}

// 删除历史订单
export const delHisOrder = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let delObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'order/delHisOrder',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(delObj)
    })
}

// 通过用户ID得到已经购买的订单信息
export const byOfficalUsersIDGetPayOrderInfo = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let delObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'order/byOfficalUsersIDGetPayOrderInfo',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(delObj)
    })
}

// 获取产品图片集合
export const getProductImgByID = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let imgObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'product/byTreeNoArrGetProductImgPath',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(imgObj)
    })
}
//获取系统公告
export const getNotice = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let systemObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'announcement/getAnnouncementAll',
        method: 'post',
        pathType: 'home',
        data: qs.stringify(systemObj)
    })
}

// 添加系统公告
export const addNotice = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let noticeObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'announcement/addAnnouncement',
        method: 'post',
        pathType: 'home',
        data: qs.stringify(noticeObj)
    })
}

// 编辑系统公告
export const editNotice = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let editObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'announcement/editAnnouncement',
        method: 'post',
        pathType: 'home',
        data: qs.stringify(editObj)
    })
}

// 删除系统公告
export const deleteNotice = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let delObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'announcement/delAnnouncement',
        method: 'post',
        pathType: 'home',
        data: qs.stringify(delObj)
    })
}

// 获取主账号信息
export const getOfficalUsersByPrimary = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let offObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'officalUsers/byOfficalUsersIDGetInfo',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(offObj)
    })
}
//添加子账号
export const addSubUserInfo = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let subUserObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'subUsers/addSubUsers',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(subUserObj)
    })
}

// 编辑子账号信息
export const editSubUserInfo = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let editSubUserObj = Object.assign({}, data, newObj)
    console.log(editSubUserObj);
    return axios.request({
        url: 'subUsers/editSubUsers',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(editSubUserObj)
    })
}

// 单独修改子账号状态
export const editSubUserStatus = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let statusObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'subUsers/updateSubUsersIsUse',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(statusObj)
    })
}

// 删除子账号
export const deleteSubUsersInfo = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let subObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'subUsers/delSubUsers',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(subObj)
    })
}

//查询单条子账号信息
export const getSubUserInfoSingle = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let subObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'subUsers/findSubUsers',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(subObj)
    })
}
// 获取主账号下所有子账号
export const getAllSubUsersInfo = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let allObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'subUsers/getSubUsersAll',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(allObj)
    })
}

// 修改官网用户昵称
export const editOfficeUserNick = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let editObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'officalUsers/editOfficalTrueName',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(editObj)
    })
}

// 修改用户邮箱
export const editOfficeUserEmail = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let emailObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'officalUsers/editOfficalEmail',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(emailObj)
    })
}

// 修改用户手机号
export const editOfficealPhone = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let phoneObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'officalUsers/editOfficalPhone',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(phoneObj)
    })
}

// 修改主账号用户微信绑定
export const editBindOfficealWechat = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let wechatObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'officalUsers/editOfficalWeChatOpenID',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(wechatObj)
    })
}

// 修改官网用户微信公众号唯一标识
export const editOfficalUnionID = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let wechatObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'officalUsers/editOfficalUnionID',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(wechatObj)
    })
}

// 修改子账号微信绑定
export const editBindSubusersWechat = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let wechatObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'subUsers/separateModification',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(wechatObj)
    })
}

// 查询单条官网用户信息
export const findOfficalUsers = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let userObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'officalUsers/findOfficalUsers',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(userObj)
    })
}
// 获取企业基本信息
export const getCompanyBaseInfo = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let comObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'userCompany/findUserCompany',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(comObj)
    })
}

// 上传图片
export const upLoadImage = (data) => {
    return axios.request({
        url: 'image/otherPlaceImgUpload',
        method: 'post',
        pathType: 'resource',
        data: data
    })
}

// 修改提交企业认证信息
export const editUserCompany = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let comObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'userCompany/editUserCompany',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(comObj)
    })
}

// 获取企业认证状态
export const getAuditStatus = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let comObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'userCompany/findUserCompany',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(comObj)
    })
}

// 获取产品分类列表
export const getProductClass = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let classObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'product/getProductAll',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(classObj)
    })
}

// 获取通信服务产品信息
export const getCommServerModeAll = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let serverObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'commServerMode/getCommServerModeAll',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(serverObj)
    })
}

// 获取历史服务产品信息
export const getHistoryServerAll = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let historyObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'historyServerProduct/getHistoryServerProductAll',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(historyObj)
    })
}

// 通过treeNo获取产品信息
export const findProductInfoByTreeNo = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let findObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'product/findProduct',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(findObj)
    })
}

// 获取通信服务
export const getCommServerByServerModeID = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let modeObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'communicateServer/byCommServerModeIDGetCommInfo',
        method: 'post',
        pathType: 'gateway',
        data: qs.stringify(modeObj)
    })
}

// 获取历史服务
export const getHistoryServerByModeID = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let hisObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'historyServer/byHisServerModeIDGetHisInfo',
        method: 'post',
        pathType: 'gateway',
        data: qs.stringify(hisObj)
    })
    // return axios.request({ url: 'historyServerTable/getHistoryServerTableAll', method: 'post', pathType: 'gateway', data: qs.stringify(hisObj) })
}

//获取审核的公司信息
export const getBeingAudited = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let beObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'userCompany/getUserCompanyAll',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(beObj)
    })
}

// 添加企业认证信息
export const addUserCompany = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let beObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'userCompany/addUserCompany',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(beObj)
    })
}

// 修改企业信息认证状态
export const editCompanyStatus = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let deitObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'userCompany/updateReviewState',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(deitObj)
    })
}

// 添加审核失败原因
export const addReasonFailure = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let failObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'userCompany/updateNotPassReason',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(failObj)
    })
}

// 删除认证信息
export const deleteApprove = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let delObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'userCompany/delUserCompany',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(delObj)
    })
}

// 获取通信服务版本
export const getCommonServer = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let commonObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'communicateServer/byCommServerModeIDGetCommInfo',
        method: 'post',
        pathType: 'gateway',
        data: qs.stringify(commonObj)
    })
}

// 添加通信服务版本
export const addCommServerVersion = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let addCommObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'commServerMode/addCommServerMode',
        method: 'post',
        pathType: 'gateway',
        data: qs.stringify(addCommObj)
    })
}

// 编辑通信服务版本
export const editCommServerVersion = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let editCommObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'commServerMode/editCommServerMode',
        method: 'post',
        pathType: 'gateway',
        data: qs.stringify(editCommObj)
    })
}

// 删除指定通信服务版本
export const deletePointCommServerVersion = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let delCommObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'commServerMode/delCommServerMode',
        method: 'post',
        pathType: 'gateway',
        data: qs.stringify(delCommObj)
    })
}

// 获取历史服务版本
export const getHistoryServer = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let commonObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'historyServer/byHisServerModeIDGetHisInfo',
        method: 'post',
        pathType: 'gateway',
        data: qs.stringify(commonObj)
    })
}

// 添加历史服务版本
export const addHistoryServerVersion = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let addHisObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'historyServerProduct/addHistoryServerProduct',
        method: 'post',
        pathType: 'gateway',
        data: qs.stringify(addHisObj)
    })
}

// 编辑历史服务版本
export const editPointHistoryServerVersion = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let editHisObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'historyServerProduct/editHistoryServerProduct',
        method: 'post',
        pathType: 'gateway',
        data: qs.stringify(editHisObj)
    })
}

// 删除指定历史服务版本
export const deletePointHistoryServerVersion = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let delCommObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'historyServerProduct/delHistoryServerProduct',
        method: 'post',
        pathType: 'gateway',
        data: qs.stringify(delCommObj)
    })
}

// 获取全部驱动
export const getAllDriveEvent = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let devObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'driverLib/getDriverLibAll',
        method: 'post',
        pathType: 'gateway',
        data: qs.stringify(devObj)
    })
}

// 获取已绑定驱动信息
export const getBindDriveEvent = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let devObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'commServerMode/byCommServerIDGetDriverIDList',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(devObj)
    })
}

// 添加驱动事件
export const addDriveEvent = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let addDObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'commServerMode/bindCommServerIDAndDriverID',
        method: 'post',
        pathType: 'productOrder',
        data: qs.stringify(addDObj)
    })
}

// 获取系统日志
export const getLogInfoForAll = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let logObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'logInfo/getAllLogInfo',
        method: 'post',
        pathType: 'log',
        data: qs.stringify(logObj)
    })
}

// // 添加日志
// export const addLogInfoEvent = (data) => {
//     return axios.request({ url: 'logInfo/insertLogInfo', method: 'post', pathType: 'log', data: qs.stringify(data) })
// }


// 验证微信绑定
export const judgeWechatForBind = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let judgeObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'sendKey/judgeWeChatCode',
        method: 'post',
        pathType: 'msg',
        data: qs.stringify(judgeObj)
    })
}

// 官网管理员添加
export const addOfficalUserForAdmin = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let addObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'officalUsers/officalRegister',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(addObj)
    })
}

// 删除官网用户并将其余关联信息一并删除
export const delAllOfficalUsers = (data) => {
    let newObj = {
        usersID: sessionStorage.getItem('h4v2ws'),
        authToken: sessionStorage.getItem('10innt'),
        usersName: sessionStorage.getItem('6ghy5t'),
    }
    let delObj = Object.assign({}, data, newObj)
    return axios.request({
        url: 'officalUsers/delAllOfficalUsers',
        method: 'post',
        pathType: 'office',
        data: qs.stringify(delObj)
    })
}
