import Vue from 'vue'
import Vuex from 'vuex'
import pubInfo from './publicInfo'
import stage from './backstage'
import user from './usersInfo.js'
import order from './order.js'

Vue.use(Vuex)
export default new Vuex.Store({
    modules: {
        pubInfo,
        stage,
        user,
        order
    }
})
