/**
 * @param home 官网主页后台
 * @param msg 发送消息接口
 * @param lay 平台用户
 * @param office 官网用户
 * @param productOrder 产品订单
 * @param resource 资源接口
 * @param history 历史数据服务接口
 * @param gateway 网关中心服务接口
 */
export default {
    title: 'admin',
    baseUrl: {
        // 开发环境
        dev: {
            home: '/api',
            msg: '/msg',
            lay: '/lay',
            office: '/office',
            productOrder: '/productOrder',
            resource: '/resource',
            history: '/history',
            gateway: '/gateway',
            pay: '/pay',
            log: '/log',
            upiot: '/upiot',
            transfer: '/transfer',
            tempPro: '/tempPro',
        },
        // 生产环境
        pro: {
            home: 'https://www.kmmyun.com/officalgwroute/hpm/',
            msg: 'https://gws.kmmyun.com/sm/',
            lay: 'https://gws.kmmyun.com/un/',
            office: 'https://www.kmmyun.com/officalgwroute/owu/',
            productOrder: 'https://www.kmmyun.com/officalgwroute/po/',
            resource: 'https://gws.kmmyun.com/rs/',
            history: 'https://gws.kmmyun.com/hs/',
            gateway: 'https://gws.kmmyun.com/gc/',
            pay: 'https://www.kmmyun.com/officalgwroute/pm/',
            log: 'https://www.kmmyun.com/officalgwroute/logoffical/',
            upiot: 'https://ec.upiot.net/api/v2/',
            transfer: 'https://gws.kmmyun.com/ts/',
        }
    }
}
