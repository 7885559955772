import * as home from './modules/home'
import * as back from './modules/backstage'
import * as product from './modules/product'
import * as upiot from './modules/upiot'

export default {
    home,
    back,
    product,
    upiot
}
