export default {
    state: {
        userInfo: {},
        token: null,
        isLogin: 0,
        appid: null,
        secret: null,
        jumpWechat: null
    },
    mutations: {
        setusersBaseInfo(state, obj) {
            state.userInfo = obj
        },
        setNick(state, item) {
            state.userInfo.officalTrueName = item
        },
        setPhone(state, item) {
            state.userInfo.phone = item
        },
        setEmail(state, item) {
            state.userInfo.email = item
        },
        setAppID(state, val) {
            state.appid = val
        },
        setSecret(state, val) {
            state.secret = val
        },
        setJumpWechat(state, val) {
            state.jumpWechat = val
        }
    }
}
