// 二次封装axios 拦截器

import axios from 'axios'
import config from '../config/index'
import rootVue from '../main.js'
import store from '../store'
import global from '../common/tokenGlobal'
// 设置配置 根据开发和生产环境不一样
let baseUrl = "";
if (process.env.NODE_ENV === 'development') {
    baseUrl = config.baseUrl.dev
} else {
    baseUrl = config.baseUrl.pro
}

class HttpRequest {
    // constructor(baseUrl) {
    constructor() {
        this.baseUrl = baseUrl
    }

    getInsideConfig(options) {
        const pathType = options.pathType
        let realPath = "";
        for (let i in baseUrl) {
            if (pathType === i) {
                realPath = baseUrl[i]
            }
        }
        const config = {
            baseURL: realPath,
            header: {}
        }
        return config
    }

    interceptors(instance) {
        instance.interceptors.request.use(function (config) {
            // 在发送请求之前做些什么
            let token = sessionStorage.getItem('9iwe4r')
            if (token != null && token != "" && token != undefined) {
                config.headers.kmmToken = token
            }
            return config
        }, function (error) {
            // 对请求错误做些什么
            return Promise.reject(error)
        })
        instance.interceptors.response.use(function (response) {
            // 对响应数据做点什么
            if (JSON.stringify(response.data).indexOf('Token已被顶下线') > -1) {
                if (global.TokenInvalidFlag == 0) {
                    global.TokenInvalidFlag = 1
                    window.alert('当前账号在其他地方登录,您已被挤下线。')
                    store.commit('setInitAllPermission', [])
                    store.commit('setusersBaseInfo', {})
                    store.commit('clearMenu')
                    store.commit('setCurrentMenu', null)
                    store.commit('selectMenu', {})
                    window.sessionStorage.clear()
                    window.sessionStorage.setItem('4drt3d', 0)
                    window.sessionStorage.setItem('lastClickTime', window.sessionStorage.getItem('lastClickTime'))
                    rootVue.$router.replace('/')
                }

            } else {
                global.TokenInvalidFlag = 0
            }
            return response.data
        }, function (error) {
            // 对响应错误做点什么
            return Promise.reject(error)
        })
    }

    // {
    //     baseUrl: 'api/login'
    // }
    request(options) {
        // 请求
        //  /api/getList /api/getHome
        const instance = axios.create()
        // 技巧
        options = {...(this.getInsideConfig(options)), ...options}
        this.interceptors(instance)
        return instance(options)
    }
}

// export default new HttpRequest(baseUrl)
export default new HttpRequest()
