import Vue from 'vue'
import VueRouter from 'vue-router'
import Back from '../api/data.js'
import store from '../store'
import rootVue from '../main.js'

Vue.use(VueRouter)
const routes = [
    {path: '/', redirect: '/home'},
    {
        path: '/home',
        name: 'Home',
        component: resolve => require(['@/views/Home.vue'], resolve),
        redirect: '/main',
        meta: {requireAuth: false, title: '官网'},

        children: [
            {
                path: '/main',
                name: 'Main',
                component: resolve => require(['@/components/index/main.vue'], resolve),
                meta: {requireAuth: false, title: '首页'}
            },
            {
                path: '/aboutus',
                name: 'Aboutus',
                component: resolve => require(['@/views/office/aboutUs/aboutUs.vue'], resolve),
                meta: {requireAuth: false, title: '关于我们'}
            },
            {
                path: '/immediately',
                name: 'Immediately',
                component: resolve => require(['@/views/office/other/immediatelyConsult/immediatelyConsult.vue'], resolve),
                meta: {requireAuth: false, title: '立即咨询'}
            },

            {
                path: '/ProductLowCode',
                name: 'ProductLowCode',
                component: resolve => require(['@/views/office/product/platform/product-low-code.vue'], resolve),
                meta: {requireAuth: false, title: '低代码平台'}
            },
            {
                path: '/ProductBigData',
                name: 'ProductBigData',
                component: resolve => require(['@/views/office/product/platform/product-big-data.vue'], resolve),
                meta: {requireAuth: false, title: '大数据平台'}
            },


            {
                path: '/ProductErp',
                name: 'ProductErp',
                component: resolve => require(['@/views/office/product/application/product-app-erp.vue'], resolve),
                meta: {requireAuth: false, title: '进销存'}
            },
            {
                path: '/ProductTask',
                name: 'ProductTask',
                component: resolve => require(['@/views/office/product/application/product-app-task.vue'], resolve),
                meta: {requireAuth: false, title: '小工单生产管理'}
            },
            {
                path: '/ProductWms',
                name: 'ProductWms',
                component: resolve => require(['@/views/office/product/application/product-app-wms.vue'], resolve),
                meta: {requireAuth: false, title: 'wms'}
            },
            {
                path: '/ProductMes',
                name: 'ProductMes',
                component: resolve => require(['@/views/office/product/application/product-app-mes.vue'], resolve),
                meta: {requireAuth: false, title: 'MES'}
            },
            {
                path: '/ProductIot',
                name: 'ProductIot',
                component: resolve => require(['@/views/office/product/application/product-app-iot.vue'], resolve),
                meta: {requireAuth: false, title: 'iot'}
            },
            {
                path: '/ProductOa',
                name: 'ProductOa',
                component: resolve => require(['@/views/office/product/application/product-app-oa.vue'], resolve),
                meta: {requireAuth: false, title: 'OA办公'}
            },


            {
                path: '/CaseErpTask',
                name: 'CaseErpTask',
                component: resolve => require(['@/views/office/case/erpTaskCase.vue'], resolve),
                meta: {requireAuth: false, title: '中小企业进销存+小工单'}
            },
            {
                path: '/otdrCase',
                name: 'otdrCase',
                component: resolve => require(['@/views/office/case/otdrCase.vue'], resolve),
                meta: {requireAuth: false, title: 'OTDR测试系统'}
            },
            {
                path: '/wmsCase',
                name: 'wmsCase',
                component: resolve => require(['@/views/office/case/wmsCase.vue'], resolve),
                meta: {requireAuth: false, title: 'WMS'}
            },
            {
                path: '/oaCase',
                name: 'oaCase',
                component: resolve => require(['@/views/office/case/oaCase.vue'], resolve),
                meta: {requireAuth: false, title: 'OA'}
            },
            {
                path: '/hmi',
                name: 'hmi',
                component: resolve => require(['@/views/office/hardware/hmi.vue'], resolve),
                meta: {requireAuth: false, title: 'hmi'}
            },
            {
                path: '/Gateway',
                name: 'Gateway',
                component: resolve => require(['@/views/office/hardware/Gateway.vue'], resolve),
                meta: {requireAuth: false, title: 'Gateway'}
            },
        ],
    }
]


const router = new VueRouter({
    mode: 'history',
    routes,
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}


// 挂载路由导航守卫
let startTime = Date.now()
let currentTime
let standingTime = 0
let pageName = []
router.beforeEach((to, from, next) => {
    // 获取微信扫码成功后返回令牌
    let code = getUrlKey('code')
    // 加载动态菜单和路由
    let isLogin = Boolean(Number(sessionStorage.getItem('4drt3d')))
    if (to.meta.requireAuth) {//是否需要登录后才能查看
        if (!isLogin) {//登录状态 -- 未登录
            next({name: 'Login', query: {'redirect': from.name}})
        } else {
            let userType = sessionStorage.getItem('4ugtyf')//0-主账号；1-子账号
            if (userType == 1) {//子账号
                let tempSub = {
                    subUsersID: sessionStorage.getItem('h4v2ws')
                }
                Back.back.getSubUserInfoSingle(tempSub).then((returnS) => {
                    if (returnS.code == 1) {
                        let judgeSub = returnS.object
                        if (judgeSub == null) {
                            window.alert('当前子账号已不存在')
                            clearSessionstage()
                        } else {
                            Back.back.getOfficalUsersByPrimary({officalUsersID: judgeSub.mainUsersID}).then((returnSP) => {
                                if (returnSP.code == 0) {
                                    if (!Boolean(returnSP.object.isUse)) {
                                        window.alert('当前账号主账号已被禁用，无法进行后续操作')
                                        clearSessionstage()
                                    } else {
                                        if (!Boolean(judgeSub.isUse)) {
                                            window.alert('当前子账号已被禁用，无法进行后续操作')
                                            clearSessionstage()
                                        }
                                    }
                                }
                            })
                        }
                    } else {
                        // window.alert('当前子账号已不存在')
                        clearSessionstage()
                    }
                })
            }
            if (userType == 0) {//主账号
                let primary = {
                    officalUsersID: sessionStorage.getItem('r54tfg')
                }
                Back.back.getOfficalUsersByPrimary(primary).then((returnP) => {
                    console.log(returnP);
                    if (returnP.code == 0) {
                        let judgeObj = returnP.object
                        if (judgeObj == null) {
                            window.alert('当前主账号已不存在')
                            clearSessionstage()
                        } else {
                            if (!Boolean(judgeObj.isUse)) {
                                window.alert('当前主账号已被禁用，无法进行后续操作')
                                clearSessionstage()
                            }
                        }
                    } else {
                        // window.alert('当前主账号已不存在')
                        clearSessionstage()
                    }
                })
            }
            next()
        }
    }
    if (to.meta.requireAuth == undefined) {
        if (!isLogin) {
            next({name: 'Login'})
        }
    }
    // 当前账号处于登录状态并且想要跳转到登录页面
    if (isLogin && to.name == 'Login') {
        next({name: 'Home'})
    }

    // to 将要访问的路径
    // from 代表从那个路径跳转过来
    // next 是一个函数，表示放行
    if (isLogin) {
        addDynamicMenuAndRoutes()
        next()
    }

    // 为微信授权登录重定向地址服务
    if (code != null && code != undefined) {
        next({
            query: {'code': code}
        })
    } else {
        next()
    }

})


/**
 *清除缓存信息
 */
function clearSessionstage() {
    store.commit('setInitAllPermission', [])
    store.commit('setusersBaseInfo', {})
    store.commit('clearMenu')
    store.commit('setCurrentMenu', null)
    store.commit('selectMenu', {})
    router.options.routes[5].children = []
    window.sessionStorage.clear()
    rootVue.$router.push('/')
}

/**
 * 加载动态菜单和路由
 */
function addDynamicMenuAndRoutes() {
    if (store.state.stage.permissionAll.length == 0 && sessionStorage.getItem('huyd2r') != undefined) {
        let tempObj = {
            usersID: sessionStorage.getItem('h4v2ws'),
            authToken: sessionStorage.getItem('10innt'),
            roleID: sessionStorage.getItem('huyd2r')
        }
        Back.back.getPermissionByRoleID(tempObj)
            .then((res) => {
                console.log(res);
                if (res.code == '1') {
                    let tempTree = getTree(res.data, '0_')
                    let tempChild = tempTree[0].children
                    let tempArr = []
                    if (tempChild.length > 0) {
                        for (let i in tempChild) {
                            let tempObj = {}
                            tempObj.viewText = tempChild[i].viewText
                            tempObj.treeNo = tempChild[i].treeNo
                            tempObj.parent = tempChild[i].parent
                            tempObj.menuImage = tempChild[i].menuImage
                            tempArr.push(tempObj)
                        }
                    }
                    store.commit('setTopMenu', tempArr)
                    store.commit('setInitAllPermission', tempTree[0])
                    let tempData = tempTree[0].children[0].children
                    store.commit('setInitLeftMenu', tempData)
                    // 添加动态路由
                    let dynamicRoutes = addDynamicRoutes(tempData)
                    router.options.routes[5].children = router.options.routes[5].children.concat(dynamicRoutes)
                    router.options.routes.push({
                        path: '*',
                        name: 'notFound',
                        component: resolve => require(['@/views/error/404.vue'], resolve),
                        meta: {requireAuth: false}
                    })
                    router.addRoutes(router.options.routes)
                } else {
                    store.commit('setInitAllPermission', [])
                    store.commit('setusersBaseInfo', {})
                    store.commit('clearMenu')
                    store.commit('setCurrentMenu', null)
                    store.commit('selectMenu', {})
                    window.sessionStorage.clear()
                    rootVue.$router.replace('/')
                }
            })
            .catch(function (res) {
                alert(res)
            });
    }
}

/**
 * 添加动态(菜单)路由
 * @param {*} menuList 菜单列表
 * @param {*} routes 递归创建的动态(菜单)路由
 */
function addDynamicRoutes(menuList = [], routes = []) {
    var temp = [], limit = []
    for (var i = 0; i < menuList.length; i++) {
        if (menuList[i].children && menuList[i].children != null) {
            temp = temp.concat(menuList[i].children)
            if (menuList[i].href != '') {
                const compon = menuList[i].href
                let tempObj = {
                    component: resolve => {
                        require(['@/' + compon + '.vue'], resolve)
                    },
                    name: menuList[i].menuContent,
                    path: menuList[i].onClick,
                    meta: {
                        requireAuth: Boolean(Number(menuList[i].isLogin)),
                        title: menuList[i].viewText
                    },
                }
                routes.push(tempObj)
            }
        } else {
            if (menuList[i].href != '') {
                const component = menuList[i].href
                let temp = {
                    component: resolve => {
                        require(['@/' + component + '.vue'], resolve)
                    },
                    name: menuList[i].menuContent,
                    path: menuList[i].onClick,
                    meta: {
                        requireAuth: Boolean(Number(menuList[i].isLogin)),
                        title: menuList[i].viewText
                    },
                }
                routes.push(temp)
            } else {
                if (menuList[i].isShow == 1) {
                    limit.push(menuList[i].buttonID)
                }
            }
        }
    }
    if (temp.length >= 1) {
        addDynamicRoutes(temp, routes)
    } else {
    }
    if (limit.length >= 1) {
        store.commit('setPerms', limit)
    }
    return routes
}

// 整理树形
function getTree(menuList, parentID) {
    var tree = [];
    var temp;
    for (var i = 0; i < menuList.length; i++) {
        if (menuList[i].parent == parentID) {
            var obj = {};
            obj = menuList[i];
            temp = getTree(menuList, menuList[i].key);
            if (temp.length > 0) {
                obj.children = temp;
            }
            tree.push(obj);
        }
    }
    return tree;
}

// 获取地址栏参数
function getUrlKey(name) {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
}

export default router

